.btn-primary{background-image: linear-gradient(to right, #eb5a04, #ef7000, #f28400, #f59700, #f6aa00); border-radius: 2.5rem !important; --bs-btn-padding-y: 0.5rem !important; --bs-btn-padding-x: 2rem !important; --bs-btn-font-size: 1.25rem !important;}

.shadow-effect1{background: #fff; border-radius: 4px; text-align: center; box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);}
#customers-testimonials1 .shadow-effect1 p {font-family: 'Poppins', sans-serif; font-size: 1rem; line-height: 1.5; margin: 0 0 17px 0; font-weight: 300; padding:15px; text-align: left; font-weight: 700;}
#customers-testimonials1 .shadow-effect1 p a{display: block; padding-top: 5rem; color: #F6AA00;  text-decoration: none;}
.testimonial-name {margin: -17px auto 0; display: table; width: auto; background: #3190E7; padding: 9px 35px; border-radius: 12px; text-align: center; color: #fff; box-shadow: 0 9px 18px rgba(0,0,0,0.12), 0 5px 7px rgba(0,0,0,0.05);}
#customers-testimonials1 .item {text-align: center; margin-bottom:40px; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out;}
.owl-carousel .owl-item .shadow-effect1{}
.owl-carousel .owl-item .shadow-effect1 img {width: 100%; margin-bottom: 15px;}
#customers-testimonials1.owl-carousel .owl-dots .owl-dot.active span,
#customers-testimonials1.owl-carousel .owl-dots .owl-dot:hover span {background: #3190E7; transform: translate3d(0px, -50%, 0px) scale(0.7);}
#customers-testimonials1.owl-carousel .owl-dots{display: inline-block; width: 100%; text-align: center;}
#customers-testimonials1.owl-carousel .owl-dots .owl-dot{display: inline-block;}
#customers-testimonials1.owl-carousel .owl-dots .owl-dot span {background: #3190E7; display: inline-block; height: 20px; margin: 0 2px 5px; transform: translate3d(0px, -50%, 0px) scale(0.3); transform-origin: 50% 50% 0; transition: all 250ms ease-out 0s; width: 20px;}
#customers-testimonials1 .owl-nav{text-align: center; position: absolute; left: -20%; top: 15rem;}
#customers-testimonials1 .owl-nav > button{border:1px solid #ffffff !important; width:40px; height: 40px; color: #ffffff !important; border-radius: 10rem !important;}
#customers-testimonials1 .owl-nav span{line-height: 0 !important;}
#customers-testimonials1 .owl-next{margin-left: 20px;}
#customers-testimonials .owl-item .active .center p{color:#ffffff !important;}

#customers-testimonials .owl-item .active p{color:#767473 !important;}


@media (max-width: 576px) {
.home-blogs2{padding:5rem 0;}
.home-blogs2-slider{width: 100%; padding: 2rem; margin-top: -4rem; left:0px;}
#customers-testimonials1 .owl-nav{position: relative; left: 0px; top: 0;}
}