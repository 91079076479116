@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;700;800&family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

.home-banner{background-size:cover; background-position: center; background-repeat: no-repeat; min-height: 95vh;}
.home-banner-content{padding: 20% 30% 20% 0; height: auto; overflow: hidden;}
.home-banner-content h1{font-family: 'Almarai', sans-serif; font-size: 4rem; color: #ffffff; font-weight: 800;}
.home-banner-content p{font-family: 'Poppins', sans-serif; font-size: 1.2rem; color: #ffffff; font-weight: 300;}

.scroll-text{font-family: 'Almarai', sans-serif; color: #ffffff; font-size: 1.4rem; font-weight: 700; position: absolute; transform: rotate(-90deg); transform-origin: 0 0; right:0; bottom: 2rem;}

@media (max-width: 576px) {
.home-banner-content{padding: 20% 0;}
.home-banner-content h1{font-size: 3rem;}
.home-banner-content .btn{width: 100%; margin-bottom: 1rem;}
.scroll-text{display: none;}
}

.featured-content1{height: auto; overflow: hidden; margin-right: 15%;}
.featured-content1:before{width:8px; height: 80px; background-color: #F6AA00; content: ''; display: block; position: absolute;}
.featured-content1 h2{font-family: 'Almarai', sans-serif; font-size: 4rem; color: #1C1C1C; font-weight: 700; margin-left: 35px;}
.featured-content1 p{font-family: 'Poppins', sans-serif; font-size: 1rem; color: #767473; font-weight: 300; margin-left: 35px;}

@media (min-width: 576px) and (max-width: 768px) {
.featured-content1{margin-left: 3rem; margin-right: 1rem; padding-top: 3rem;}
}

@media (max-width: 768px) {
.featured-content1 h2{font-size: 3rem;}
.featured-content1 p{font-size: 1.2rem;}
}

.home-why-akk{padding: 10rem 30%; padding-left: 15%; height: auto; overflow: hidden;}
.home-why-akk h3{font-family: 'Almarai', sans-serif; font-size: 3rem; color: #ffffff; font-weight: 700;}
.home-why-akk p{font-family: 'Poppins', sans-serif; font-size: 1rem; color: #ffffff; font-weight: 300;}
.home-why-akk p:after{margin-top: 3rem; height: 4px; width: 20%; background-color: #F6AA00; content: ''; display: block;}
.home-why-akk-iconsbox{background-color: #ffffff; padding: 45px 5rem; width: 50%; margin-top: 4rem; margin-left: 40%; position: absolute;}
.home-why-akk-iconsbox img{margin-bottom:10px;}
.home-why-akk-iconsbox h5{font-family: 'Poppins', sans-serif; font-size: 1.2rem; color: #1C1C1C; font-weight: 500; margin-bottom: 5px;}
.home-why-akk-iconsbox p{font-family: 'Poppins', sans-serif; font-size: 1rem; color: #767473; font-weight: 300; line-height: 1.2;}

.home-why-akk-iconsbox-mobile{display:none; background-color: #ffffff; padding: 2rem; width: calc(100% - 4rem); margin: 2rem;}
.home-why-akk-iconsbox-mobile img{width: 50px; height: 50px; margin-bottom:10px;}
.home-why-akk-iconsbox-mobile h5{font-family: 'Poppins', sans-serif; font-size: 1.2rem; color: #1C1C1C; font-weight: 500; margin-bottom: 5px;}
.home-why-akk-iconsbox-mobile p{font-family: 'Poppins', sans-serif; font-size: 1rem; color: #767473; font-weight: 300; line-height: 1.2;}

@media (max-width: 991.98px) {
.home-why-akk {padding: 3rem;}
.home-why-akk-iconsbox-mobile{display: block;}
.home-why-akk-iconsbox{display: none;}
}

.home-recycle-akk{background-size:cover; background-position: center; background-repeat: no-repeat;}
.home-recycle-akk .home-recycle-akk-container{margin-bottom: 0; margin-top:40%; padding: 5rem 4rem; background-color: #ffffff;}
.home-recycle-akk .home-recycle-akk-container h5{font-family: 'Poppins', sans-serif; font-size: 2rem; color: #1C1C1C; font-weight: 500; margin-bottom: 5px;}
.home-recycle-akk .home-recycle-akk-container p{font-family: 'Poppins', sans-serif; font-size: 1rem; color: #767473; font-weight: 300;}

.bg1{margin-top:6rem; height: auto; overflow: hidden;}
.bg2{background-color: #FFF8F3; margin-top: 6rem; padding: 4rem 0;}
.bg3{background-color: #020202;}
.bg4{margin:4rem; height: auto; overflow: hidden;}
.bg5{margin:6rem 0; height: auto; overflow: hidden;}

.featured-container1{background-color: #F6AA00; width: 80%; padding: 4rem 6rem; border-radius: 4rem; margin: 6rem auto; -webkit-box-shadow: 0px 6px 15px 5px rgba(118,116,115,0.35); -moz-box-shadow: 0px 6px 15px 5px rgba(118,116,115,0.35); box-shadow: 0px 6px 15px 5px rgba(118,116,115,0.35);}
.featured-container1 h5{font-family: 'Poppins', sans-serif; font-size: 1.8rem; color: #ffffff; font-weight: 500; margin-bottom: 5px;}
.featured-container1 p{font-family: 'Poppins', sans-serif; font-size: 1rem; color: #ffffff; font-weight: 300; line-height: 1.2;}
.featured-container1 .btn{--bs-btn-padding-y:1rem;}

@media (max-width: 576px) {
.home-recycle-akk .home-recycle-akk-container{margin-top:2rem; border:0px;padding: 3rem 2rem;}
.bg2 img{margin-bottom: 2rem;}
.bg4{margin: 4rem 2rem;}
.bg5{margin:4rem 0; }
.featured-container1{padding: 2rem; border-radius: 2rem; width: 90%;}
.featured-container1 p{font-size: 1.2rem;}
}
@media (min-width: 576px) and (max-width: 768px) {
.featured-container1{padding: 3rem 4rem;}
.featured-container1 p{font-size: 1.2rem;}
}

.text-highlighter1{height: auto; overflow: hidden;}
.text-highlighter1:after{margin-top: 2rem; height: 4px; width: 30%; background-color: #F6AA00; content: ''; display: block;}

.a1{font-family: 'Almarai', sans-serif; font-size: 3.2rem; color: #1C1C1C; font-weight: 700; margin-left: 35px;}
.a2{font-family: 'Poppins', sans-serif; font-size: 1rem; color: #767473; font-weight: 300; margin-left: 35px;}
.a3{font-family: 'Poppins', sans-serif; font-size: 1.8rem; color: #F6AA00; font-weight: 500;}
.a4{font-family: 'Poppins', sans-serif; font-size: 1rem; color: #767473; font-weight: 300;}
.a5{font-family: 'Almarai', sans-serif; font-size: 3rem; color: #1C1C1C; font-weight: 700;}
.a5 span{font-family: 'Almarai', sans-serif; font-size: 1.8rem; color: #1C1C1C; font-weight: 700; display: block; margin: 15px 0;}
.a6{text-align: center; font-family: 'Almarai', sans-serif; font-size: 1.2rem; height: auto; overflow: hidden; margin: 1rem  0;}
.a6 span{color: #F6AA00; display: block; font-family: 'Poppins', sans-serif; font-size: 4rem; font-weight: 700; padding-top: 1.2rem;}

.pd1{padding-right: 8rem; padding-bottom: 1rem;}
.pd2{padding: 40px 0;}

@media (max-width: 576px) {
.pd1{padding: 0px;}
.a1{margin-left: 0px; font-size: 2rem;}
.a2{margin-left: 0px;}
}
@media (min-width: 576px) and (max-width: 768px) {
.pd1{padding-right: 3rem; padding-bottom: 1rem;}
.a2{font-size: 1.2rem;}
}

.home-blogs{background-color: #FFF8F3; width: 100%; padding-top: 6rem; height: auto;overflow: hidden;}
.home-blogs-slider{width: 55%; margin-right: 45%; z-index: 1; max-height: 600px; overflow: hidden; position: absolute;}
.home-blogs-slider .owl-item{max-height: 100%; overflow: hidden;}
.home-blogs-container{background-color: #ffffff; padding:45px; z-index: 2; position: relative;}
.home-blogs-container h5{font-family: 'Almarai', sans-serif; font-size: 3rem; color: #1C1C1C; font-weight: 700;}
.home-blogs-container h5 span{display: block; font-size: 1.8rem; color: #F6AA00; display: block;}
.home-blogs-container p{font-family: 'Poppins', sans-serif; font-size: 1rem; color: #767473; font-weight: 300;}
.home-news-container{position: relative; margin-top: 6rem;}

@media (max-width: 768px) {
.home-blogs{padding:2rem;}
.home-blogs-slider{width: 100%; margin: 1rem 0; position: relative;}
.home-news-container{position: relative; margin-top: 1rem;}
.home-blogs-container{padding: 20px;}
.home-blogs-container h5{font-size: 2rem;}
}

.home-blogs2{background-color: #020202; width: 100%; height: auto; text-align: center; padding:10rem 0 20rem 0;}
.home-blogs2 h5{color: #F6AA00; font-family: 'Almarai', sans-serif; font-size: 4rem; font-weight: 700;}
.home-blogs2-slider{position: relative; left: -20%; margin-top: 4rem; width: 120%;}
/*BLOGS*/
.shadow-effect1{background: #fff; border-radius: 4px; text-align: center; box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);}
#customers-testimonials1 .shadow-effect1 p {font-family: 'Poppins', sans-serif; font-size: 1rem; line-height: 1.5; margin: 0 0 17px 0; font-weight: 300; padding:15px; text-align: left; font-weight: 700;}
#customers-testimonials1 .shadow-effect1 p span{-webkit-box-orient: vertical; -webkit-line-clamp: 1; color: #515a61; display: -webkit-box; overflow:hidden;}
#customers-testimonials1 .shadow-effect1 p a{display: block; padding-top: 5rem; color: #F6AA00;  text-decoration: none;}
.testimonial-name {margin: -17px auto 0; display: table; width: auto; background: #3190E7; padding: 9px 35px; border-radius: 12px; text-align: center; color: #fff; box-shadow: 0 9px 18px rgba(0,0,0,0.12), 0 5px 7px rgba(0,0,0,0.05);}
#customers-testimonials1 .item {text-align: center; margin-bottom:40px; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out;}
.owl-carousel .owl-item .shadow-effect1{}
.owl-carousel .owl-item .shadow-effect1 img {width: 100%; margin-bottom: 15px;}
#customers-testimonials1.owl-carousel .owl-dots .owl-dot.active span,
#customers-testimonials1.owl-carousel .owl-dots .owl-dot:hover span {background: #3190E7; transform: translate3d(0px, -50%, 0px) scale(0.7);}
#customers-testimonials1.owl-carousel .owl-dots{display: inline-block; width: 100%; text-align: center;}
#customers-testimonials1.owl-carousel .owl-dots .owl-dot{display: inline-block;}
#customers-testimonials1.owl-carousel .owl-dots .owl-dot span {background: #3190E7; display: inline-block; height: 20px; margin: 0 2px 5px; transform: translate3d(0px, -50%, 0px) scale(0.3); transform-origin: 50% 50% 0; transition: all 250ms ease-out 0s; width: 20px;}
#customers-testimonials1 .owl-nav{text-align: center; position: absolute; left: -20%; top: 15rem;}
#customers-testimonials1 .owl-nav > button{border:1px solid #ffffff !important; width:40px; height: 40px; color: #ffffff !important; border-radius: 10rem !important;}
#customers-testimonials1 .owl-nav span{line-height: 0 !important;}
#customers-testimonials1 .owl-next{margin-left: 20px;}

/*BLOGS END*/
@media (max-width: 576px) {
.home-blogs2{padding:5rem 0;}
.home-blogs2-slider{width: 100%; padding: 2rem; margin-top: -4rem; left:0px;}
#customers-testimonials1 .owl-nav{position: relative; left: 0px; top: 0;}
}

.footer{background-color: #020202;  padding: 4rem 0 1.4rem 0; margin-top: 2rem;}
.footer .title{color: #ECEDEF; font-size: 1.8rem; font-family: 'Poppins', sans-serif; font-weight: 500;}
.footer-nav li a{color: #ECEDEF; font-weight: 300; font-size: 1rem; text-decoration: none;}
.footer .a4{color: #ECEDEF; margin-top: 1.2rem;}
.social-buttons{height:auto; overflow: hidden; margin:2rem 0;}
.social-buttons li{margin-right: 1.4rem;}
.social-buttons li a{width: 50px; height: 50px; border: 1px solid #343434; background-color: #343434; border-radius: 30px; text-align: center; display: flex; transition: all 250ms ease-out 0s;}
.social-buttons li a:hover{border-color: #ECEDEF; background-color: #020202; transition: all 250ms ease-out 0s;}
.social-buttons li a img{width: 30px; height: 30px; margin:auto;}

.scrollbox{transform: rotate(90deg);}
.scrollbox span { width: 20px; height: 20px; display: block; border-right: 2px solid #fff; border-bottom: 2px solid #fff; transform: rotate(45deg); margin: -10px; animation: scrollanim 2s infinite;}
.scrollbox span:nth-child(2) {animation-delay: -0.2s;}
.scrollbox span:nth-child(3) {animation-delay: -0.4s;}
@keyframes scrollanim {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1; transform: rotate(45deg) translate(10px, 10px);
	}
	100% {
		opacity: 0;
	}
}

/*TESTIMONIAL ENDS*/
.shadow-effect{background: #fff; padding: 20px; border-radius: 4px; text-align: center; border:1px solid #ECECEC; box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);}
#customers-testimonials .shadow-effect p {font-family: 'Poppins', sans-serif; font-size: 1rem; line-height: 1.5; margin: 0 0 17px 0; font-weight: 300; -webkit-box-orient: vertical; -webkit-line-clamp: 8; overflow: hidden; display: -webkit-box; min-height: 12rem;}
.testimonial-name {margin: -17px auto 0; display: table; width: auto; background: #3190E7; padding: 9px 35px; border-radius: 12px; text-align: center; color: #fff; box-shadow: 0 9px 18px rgba(0,0,0,0.12), 0 5px 7px rgba(0,0,0,0.05);}
#customers-testimonials .item {text-align: center; padding: 15px; margin-bottom:40px; -webkit-transform: scale3d(0.8, 0.8, 1); transform: scale3d(0.8, 0.8, 1); -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out;}
#customers-testimonials .owl-item.active.center .item {-webkit-transform: scale3d(1.0, 1.0, 1); transform: scale3d(1.0, 1.0, 1);}
#customers-testimonials .owl-item.active.center .item .shadow-effect{background-image: linear-gradient(to top, #eb5a04, #ef7000, #f28400, #f59700, #f6aa00); color: #ffffff;}
.owl-carousel .owl-item .shadow-effect img {transform-style: preserve-3d; max-width: 65px; margin-bottom:1rem;}
#customers-testimonials.owl-carousel .owl-dots .owl-dot.active span,
#customers-testimonials.owl-carousel .owl-dots .owl-dot:hover span {background: #3190E7; transform: translate3d(0px, -50%, 0px) scale(0.7);}
#customers-testimonials.owl-carousel .owl-dots{display: inline-block; width: 100%; text-align: center;}
#customers-testimonials.owl-carousel .owl-dots .owl-dot{display: inline-block;}
#customers-testimonials.owl-carousel .owl-dots .owl-dot span {background: #3190E7; display: inline-block; height: 20px; margin: 0 2px 5px; transform: translate3d(0px, -50%, 0px) scale(0.3); transform-origin: 50% 50% 0; transition: all 250ms ease-out 0s; width: 20px;}
#customers-testimonials .owl-nav{text-align: center;}
#customers-testimonials .owl-nav > button{border:1px solid #F6AA00 !important; width:40px; height: 40px; color: #F6AA00 !important; border-radius: 10rem !important;}
#customers-testimonials .owl-nav span{line-height: 0 !important;}
#customers-testimonials .owl-next{margin-left: 20px;}
/*TESTIMONIAL ENDS*/

/*hamburger menu*/
.nav-quick-top{display: block;}
.nav-quick-top .quick-nav{padding: 10px 0; margin-right: 6rem; color: #4E4E4E; font-family: 'Poppins', sans-serif; font-size: 1rem; text-decoration: none; font-weight: 500; transition: all 0.2s ease-in-out;}
.nav-quick-top .quick-nav:hover{color: #F6AA00; text-decoration: none; transition: all 0.2s ease-in-out;}

@media (max-width: 576px) {
	.nav-quick-top{display: none;}
}

.hamburger-menu {z-index:20; position: relative; cursor: pointer; top: -15px;}

.bar{width: 50px; height: 5px; float: right;  text-align: right; position: relative; transform: translateY(25px); background: rgba(0,0,0, 1); transition: all 0ms 300ms;}
.bar.animate {background: rgba(0,0,0, 0); width: 50px;}
.bar:before {width: 30px; height: 5px; content: ""; position: absolute; left: 0; bottom: 12px; background: rgba(0,0,0, 1); transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);}
.bar:after {width: 40px; height: 5px; content: ""; position: absolute; left: 0; top: 12px; background: rgba(0,0,0, 1); transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);}
.bar.animate:after {top: 0; width: 50px; background: rgba(0,0,0, 1);  transform: rotate(45deg); transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;}
.bar.animate:before {bottom: 0; width: 50px; background: rgba(0,0,0, 1);  transform: rotate(-45deg); transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;}

/*nav menu*/
.show {left:0%; opacity:1;}

.hide {opacity:0; left:100%; display: none;}

.mobile-nav {background:rgba(255,255,255,1); position:fixed; top:0; right: 0; margin-left:60vw; padding-left:4vw; height:100%; text-align:left; transition: .2s ease; z-index: 10;-webkit-box-shadow: -5px 0px 13px -4px rgba(0,0,0,0.5); -moz-box-shadow: -5px 0px 13px -4px rgba(0,0,0,0.5); box-shadow: -5px 0px 13px -4px rgba(0,0,0,0.5);}
@media (max-width: 768px) {
.mobile-nav {margin-left:10vw;width:90vw;}
  }
.mobile-nav ul{position:relative; top:50%; transform: translateY(-50%); list-style:none;}
.mobile-nav ul li{margin-bottom: 1.5rem;}
.mobile-nav a {text-align:center; font-size:1.2rem; color:#ffffff; opacity:0; transition:opacity .9s ease-in-out;}
.mobile-nav.show a {text-align:left; font-family: 'Poppins', sans-serif; font-size:1.2rem; color:#1C1C1C; opacity:1; text-decoration: none; transition: all 0.2s ease-in-out;}
.mobile-nav a:hover {color:#EB5A04; transition: all 0.2s ease-in-out;}
.mobile-nav.show a.active:after{width: 40px; height: 4px; background-color:#EB5A04; display: block; content: '';}

.nav_info{text-align:left; font-family: 'Heebo', sans-serif; font-size:1.8rem; color:#1C1C1C;}
.nav_info a{display: block; font-size: 1.4rem !important;}

@media (max-width: 768px) {
.mobile-nav.show a {font-size:2rem;}
.nav_info a{font-size: 2rem !important;}
  }
/*hamburger menu*/

/*INNER CSS STARTS*/
.heading_banner_sm{background-color:#212121; background-size: cover; background-position: center; height: 14rem; padding-top: 10rem; margin-bottom:6%;}
.heading_banner_sm h1{font-family: 'Almarai', sans-serif; font-size: 3rem; color: #ffffff; font-weight: 700;}
.heading_banner_auto{background-color:#FEF2D9; background-size: cover; background-position: center; background-repeat: no-repeat; height: auto; overflow: hidden; padding-top: 1rem; margin-bottom:6%; z-index: 1; position: relative;}
.heading_banner_auto p{color: #ffffff;}
.heading_banner_sm .breadcrumb{margin: 20px 0; color: #ffffff;font-size: 1rem;}
.heading_banner_sm .breadcrumb a{color: #F6AA00;  font-weight: 700; text-decoration: none;}
.heading_banner_sm .breadcrumb .active{color: #ffffff;  font-weight: 400;}

.sell_car_banner{height: auto; overflow: hidden; padding:45px 0;}
.sell_car_banner_info{height: auto; overflow: hidden; margin-top: 2rem; list-style-type: none;}
.sell_car_banner_info li{width: 100%; height: auto; overflow: hidden; display: block; margin-bottom: 1rem;}
.sell_car_banner_info li img{float: left; margin-right: 10px;}
.sell_car_banner_info li p{float: left; color: #ffffff; font-size: 1.2rem; line-height: 1; font-family: 'Poppins', sans-serif; font-weight: 500;}
.sell_car_banner_holder{background-color: #ffffff; width: 100%; margin-bottom: 1rem; height: auto; overflow: hidden; border-radius: 25px; padding:25px 15px;}
.sell_car_banner_holder h1{font-family: 'Almarai', sans-serif; font-size: 2rem; color: #1C1C1C; font-weight: 700; margin-bottom: 1.4rem;}
.sell_car_banner_holder .form-control{border-radius: .4rem !important;}

.sell_car_feature{height: auto; overflow: hidden; padding:45px 0;}
.sell_car_feature_ft{height: auto; overflow: hidden; padding:10% 0; text-align: center;}
.sell_car_feature_ft img{z-index: 1;}
.sell_car_feature_ft .rank{width: 100%; text-align: right; position:relative; z-index: 9;}
.sell_car_feature_ft .rank span{width: 30px; height: 30px; border-radius: 30px; background-color: #F6AA00; content: ''; color: #ffffff; font-size: 14px; font-weight: 800; text-align: center; line-height: 2; display: block; margin-left: 55%; margin-top: -20px; z-index: 2;}
.sell_car_feature_ft h4{font-family: 'Almarai', sans-serif; font-size: 1.4rem; color: #1C1C1C; font-weight: 700; margin-top: 10px;}
.sell_car_feature_ft p{font-family: 'Poppins', sans-serif; font-size: 1rem; color: #767473; font-weight: 300;}

.featured_box{margin: 10% 0; height: auto; width: 100%; overflow: hidden; border-radius: 25px; border:1px solid #E7E7E7; padding: 2rem 2.2rem;}
.featured_box iframe{border-radius: 25px;}
.featured_box h3{padding-bottom: 45px;}
.featured_list{height: auto; overflow: hidden; width: 100%; margin: 15px 0; padding-left: 0px;}
.featured_list li{width: 100%; height: auto; overflow: hidden; display: block; margin-bottom: 1rem;}
.featured_list li img{float: left; margin-right: 10px;}
.featured_list li p{float: left; color: #3B3A3A; font-size: 1.2rem; line-height: 1; font-family: 'Poppins', sans-serif; font-weight: 500;}

.accordion_faq{border:1px solid #dee2e6; margin: 5% 0 10% 0;}

.search_box{width: 100%; height: auto; overflow: hidden; border-radius: 10px; background-color: #ffffff; z-index: 2; position: relative; padding: 2.2rem 6rem; margin-top: -12%; -webkit-box-shadow: 0px -2px 10px 5px rgba(0,0,0,0.15); -moz-box-shadow: 0px -2px 10px 5px rgba(0,0,0,0.15); box-shadow: 0px -2px 10px 5px rgba(0,0,0,0.15);}
.search_box .form-select{border-radius: .4rem !important;}
.search_box .btn{border-radius: .4rem; font-size: 1.2rem; font-weight: 700;}
@media (max-width: 768px) {
.search_box{padding:2.2rem 2rem;}
.search_box .form-select{margin-bottom:15px;}
}
@media (min-width: 768px) and (max-width: 1200px){
.search_box{padding:2.2rem 2rem;}
.search_box .form-select{margin-bottom:15px;}
}

.featured_car1{border-radius: 15px; border: 1px solid #CECCCD; margin-bottom: 15px; overflow: hidden;}
.featured_car1_title{font-family: 'Almarai', sans-serif; font-size: 2rem; color: #1C1C1C; font-weight: 700; width: 100%; margin-bottom: 25px; height: auto;}
.featured_car1_title a{font-size: 1.2rem; color: #1C1C1C; text-decoration: none; font-weight: 400; text-align: right; float: right; color: #F6AA00; transition: all 0.2s ease-in-out;}
.featured_car1_title a:hover{text-decoration: underline; transition: all 0.2s ease-in-out;}
.feature_car1_img{width: 100%; height: 200px; background-color: #FEF2D9; background-position: center; background-size: cover; background-repeat: no-repeat; content: ''; display: block;}
.feature_car1_info{width: 100%; display: block; padding:2rem 1.8rem;}
.feature_car1_info a{font-family: 'Poppins', sans-serif; font-size: 1rem; color: #1C1C1C; font-weight: 500; line-height: 1; transition: all 0.2s ease-in-out; text-decoration: none;}
.feature_car1_info a:hover{color: #F6AA00; transition: all 0.2s ease-in-out;}
.feature_car1_info2{width: 100%; display: block; padding:1rem 1.2rem; height: auto; overflow: hidden;}
.feature_car1_info2 a{font-family: 'Poppins', sans-serif; font-size: 1rem; color: #1C1C1C; font-weight: 500; line-height: 1.2; display: block; transition: all 0.2s ease-in-out; text-decoration: none;}
.feature_car1_info2 a:hover{color: #F6AA00; transition: all 0.2s ease-in-out;}

.blogs_card{background: #fff; margin-top: 3rem; border-radius: 4px; width: 100%; height: auto; overflow: hidden; max-height: 720px; -webkit-box-shadow: 10px 20px 40px rgba(0, 0, 0, 0.05); box-shadow: 10px 20px 40px rgba(0, 0, 0, 0.05);}
.blogs_card .blog_banner{background-size: cover; background-repeat: no-repeat; background-position: center; width: 100%; height: 300px; content: '';}
.blogs_card .blog_intro_container{padding: 25px 20px; height: auto; overflow: hidden;}
.blogs_card .blog_intro_title{font-family: 'Almarai', sans-serif; font-size: 1.4rem; font-weight: 700; margin-bottom: 10px; color: #1C1C1C; display: block; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; line-height: 1.8rem; min-height: 3.6rem;}
.blogs_card .blog_intro_content{font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 400; color: #1C1C1C;  display: block; display: -webkit-box; line-height: 1.2rem; min-height: 3.6rem; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;}
.blogs_card .blog_intro_container a{color:#F6AA00; text-decoration: none;}

.blogs_card_small{background: #fff; margin-top: 3rem; border-radius: 4px; width: 100%; height: auto; overflow: hidden; max-height: 720px; -webkit-box-shadow: 10px 20px 40px rgba(0, 0, 0, 0.05); box-shadow: 10px 20px 40px rgba(0, 0, 0, 0.05);}
.blogs_card_small .blog_banner{background-size: cover; background-repeat: no-repeat; background-position: center; width: 100%; height: 150px; content: '';}
.blogs_card_small .blog_intro_container{padding: 25px 20px; height: auto; overflow: hidden;}
.blogs_card_small .blog_intro_title{font-family: 'Almarai', sans-serif; font-size: 1.4rem; font-weight: 700; margin-bottom: 10px; color: #1C1C1C; display: block; display: -webkit-box; line-height: 1.2; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;}
.blogs_card_small .blog_intro_content{font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 400; color: #1C1C1C;  display: block; display: -webkit-box; line-height: 1.2; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;}
.blogs_card_small .blog_intro_container a{color:#F6AA00; text-decoration: none; font-weight: 700;}

.blogs_card_main{background: #fff; border-radius: 4px; width: 100%; height: auto; overflow: hidden; -webkit-box-shadow: 10px 20px 40px rgba(0, 0, 0, 0.05); box-shadow: 10px 20px 40px rgba(0, 0, 0, 0.05);}
.blogs_card_main .blog_intro_container{padding: 25px 20px; height: auto; overflow: hidden;}
.blogs_card_main .blog_intro_title{font-family: 'Almarai', sans-serif; font-size: 2rem; font-weight: 500; margin-bottom: 10px; color: #1C1C1C;}
.blogs_card_main .blog_intro_title small{color: #F6AA00; font-size: .8rem; display: block; font-weight: 700;}
.blogs_card_main .blog_intro_content{font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 400; color: #1C1C1C; margin-top: 45px;}
