.custom-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .custom-item {
    flex: 1 0 50%; /* Two items per row for medium and small screens */
    padding: 10px;
    box-sizing: border-box;
    
    
  }
  
  @media screen and (min-width: 768px) {
    .custom-item {
      flex-basis: 25%; /* Four items per row for desktop screens */
    }
  }