.main-image-container {
    height: 500px; /* Set height for main image container */
  }
  
  .main-image {
    max-width: 100%; /* Set max width for main image */
    max-height: 100%; /* Set max height for main image */
    object-fit: contain; /* Maintain aspect ratio */
  }
  
  .thumbnails-container {
    overflow-x: auto; /* Enable horizontal scrolling for thumbnails */
  }
  
  .thumbnail {
    cursor: pointer; /* Change cursor to pointer on hover */
  }
  